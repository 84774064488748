import type { HhcaptchaType, RecaptchaType } from '@hh.ru/hhcaptcha';

import type { PostFormData } from 'Modules/prepareFormData';
import type { FetchResult } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/types';
import { useFingerprints } from 'src/hooks/useFingerprints';
import type { Verification } from 'src/models/applicant/auth';

type FingerPrints = ReturnType<typeof useFingerprints>;

export interface SignUpBody extends FingerPrints {
    firstName: string;
    lastName: string;
    login: string;
    password?: string;
    code?: string;
    backurl: string;
    postponed: boolean;
    agreementAdv: boolean;
    isSignupPage: boolean;
    isSignupMain?: boolean;
    vacancyId?: number | null;
    deleteInactiveTime?: string;
}

export interface SignUpData {
    login: string | null;
    token: string | null;
    user_id: string | null;
    backurl: string;
    verificationResult: Verification;
    hhcaptcha: HhcaptchaType;
    recaptcha: RecaptchaType;
}

export enum SignUpErrorKey {
    Invalid = 'INVALID',
    Empty = 'EMPTY',
    Unknown = 'UNKNOWN',
}

export interface PostSignUpPayload extends PostFormData, SignUpBody {}

interface SignUpValidationError {
    key: string;
    description: SignUpErrorKey;
    location: null;
}

export interface PostSignUpError {
    backurl: string;
    code: number;
    error: SignUpValidationError[];
    errors: SignUpValidationError[];
    hhcaptcha: HhcaptchaType;
    recaptcha: RecaptchaType;
}

export type PostSignUpResult = FetchResult<SignUpData, PostSignUpError>;

export type SignUpParams = Pick<
    SignUpBody,
    'firstName' | 'lastName' | 'login' | 'password' | 'code' | 'deleteInactiveTime'
> & {
    backUrl?: string;
    isSignupPage?: boolean;
    isSignupMain?: boolean;
    hasAdConsent?: boolean;
};

export interface SignUpError {
    firstName?: SignUpErrorKey;
    lastName?: SignUpErrorKey;
}

export type SignUpReturn = FetchResult<SignUpData, SignUpError>;
