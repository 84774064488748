import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import LoginForm, { useLoginForm } from 'src/components/AccountLogin/Form';
import ApplicantAuthMethodSwitcher from 'src/components/AccountLogin/components/ApplicantAuthMethodSwitcher';
import { AuthMethod, useAuthMethodSwitch } from 'src/components/AccountLogin/hooks/useAuthMethodSwitch';
import ApplicantSignInSignUpFlow from 'src/components/ApplicantSignInSignUpFlow';
import ApplicantAuthFormByCode from 'src/components/ApplicantSignInSignUpFlow/ApplicantAuthFormByCode';
import translation from 'src/components/translation';

interface ApplicantLoginFormProps {
    onSignUp?: () => void;
    errorPageLogin?: boolean;
    applicantSignInStep?: string;
    onNextStep?: (step: string) => void;
}

const TrlKeys = {
    actionByCode: 'login.action.by.code',
};

type LoginFormProps = ReturnType<typeof useLoginForm>;

const ApplicantLoginForm: TranslatedComponent<ApplicantLoginFormProps & { loginFormData: LoginFormProps }> = ({
    trls,
    onSignUp,
    onNextStep,
    applicantSignInStep,
    errorPageLogin,
    loginFormData,
}) => {
    const { authMethod, onByPassword, onByCode } = useAuthMethodSwitch();

    const formByCode = (
        <ApplicantSignInSignUpFlow
            SignIn={ApplicantAuthFormByCode}
            onSignup={() => {
                onSignUp?.();
            }}
            onNextStep={onNextStep}
            step={applicantSignInStep}
            onClickByPassword={onByPassword}
            errorPageLogin={errorPageLogin}
        />
    );

    const formByPassword = (
        <LoginForm {...loginFormData}>
            <LoginForm.Username />
            <LoginForm.Password />
            <LoginForm.Captcha />
            <LoginForm.Error />
            <VSpacing default={4} />
            <div className={classnames('account-login-actions', { 'account-login-actions_column': !errorPageLogin })}>
                <LoginForm.Submit />
                <ApplicantAuthMethodSwitcher
                    data-qa="expand-login-by-code"
                    onClick={onByCode}
                    watchedFieldName="username"
                >
                    {trls[TrlKeys.actionByCode]}
                </ApplicantAuthMethodSwitcher>
            </div>
        </LoginForm>
    );

    if (authMethod === AuthMethod.ByCode) {
        return formByCode;
    }

    return formByPassword;
};

export default translation(ApplicantLoginForm);
