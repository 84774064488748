import type { FC } from 'react';
import { Field } from 'react-final-form';

import type { ApplicantLoginFormValues } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import { ADV_AGREEMENT_FIELD } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import AdvertisementAgreementCheckbox from 'src/components/AdvertisementAgreement/Checkbox';
import { useShowAdvertisementAgreement } from 'src/components/AdvertisementAgreement/useShowAdvertisementAgreement';
import AdvertisementSubscriptionApplicant from 'src/components/AdvertisementSubscription/AdvertisementSubscriptionApplicant';
import { useShowAdvertisementSubscriptionApplicant } from 'src/components/AdvertisementSubscription/useShowAdvertisementSubscriptionApplicant';

const ADVERTISEMENT_AGREEMENT_ARTICLE_ID = '32356';

const AdvAgreementField: FC = () => {
    const showAdvertisementAgreement = useShowAdvertisementAgreement();
    const showAdvertisementSubscriptionApplicant = useShowAdvertisementSubscriptionApplicant();

    return (
        <Field<ApplicantLoginFormValues['advAgreement']>
            name={ADV_AGREEMENT_FIELD}
            type="checkbox"
            render={({ input }) => {
                return (
                    <>
                        {showAdvertisementAgreement && (
                            <AdvertisementAgreementCheckbox
                                {...input}
                                value={String(input.value)}
                                articleId={ADVERTISEMENT_AGREEMENT_ARTICLE_ID}
                            />
                        )}
                        {showAdvertisementSubscriptionApplicant && <AdvertisementSubscriptionApplicant input={input} />}
                    </>
                );
            }}
        />
    );
};

export default AdvAgreementField;
