import { useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import applicantRegistrationSuccessExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/registration/applicant_registration_success';
import { useSelector } from '@hh.ru/front-static-app';

import sendFbPixelRegistrationConversion from 'HH/SendFbPixelRegistrationConversion';
import prepareFormData from 'Modules/prepareFormData';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useFingerprints } from 'src/hooks/useFingerprints';
import { usePostponedVacancy } from 'src/hooks/usePostponedVacancy';

import {
    SignUpErrorKey,
    type PostSignUpPayload,
    type SignUpParams,
    type SignUpReturn,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useSignUp/types';
import { postSignUp } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useSignUp/utils';

interface UseSignUp {
    isLoading: boolean;
    signUp: (params: SignUpParams) => Promise<SignUpReturn>;
}

const useSignUp = (): UseSignUp => {
    const currentVacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const search = useSelector((state) => state.router.location.search);

    const [isLoading, setIsLoading] = useState(false);

    const fingerprints = useFingerprints('applicant');
    const { postponedVacancy, postponedVacancyInFavorites } = usePostponedVacancy();
    const postponedVacancyId = postponedVacancy?.vacancyId;
    const sendDV360Analytics = useDV360Analytics();
    const addEventPixels = useAddEventPixelsRu();
    const { addNotification } = useNotification();

    const signUp: UseSignUp['signUp'] = async ({
        backUrl = '/',
        isSignupPage = false,
        hasAdConsent = false,
        ...params
    }) => {
        setIsLoading(true);

        try {
            const vacancyId = currentVacancyId ?? (postponedVacancyInFavorites ? null : postponedVacancyId);
            const postponed = search.includes('postponed');

            const data: PostSignUpPayload = {
                ...params,
                ...fingerprints,
                backurl: backUrl,
                vacancyId,
                postponed,
                agreementAdv: hasAdConsent,
                isSignupPage,
            };
            const formData = prepareFormData(data) as unknown as PostSignUpPayload;
            const result = await postSignUp(formData);
            setIsLoading(false);

            if (result.success) {
                if (result.data.verificationResult.success) {
                    applicantRegistrationSuccessExternal({
                        params: {
                            main: {
                                dm_events: {
                                    applicant_register: 'site',
                                },
                            },
                        },
                    });
                    sendDV360Analytics('APPLICANT_REGISTERED');
                    addEventPixels('B2C_APPLICANT_REGISTRATION');
                    // tempexp_25028_nextline
                    sendFbPixelRegistrationConversion(false);
                    // tempexp_32623_next-line
                    Analytics.sendEvent('b2c', 'register', 'beacon_test');

                    return { success: true, data: result.data };
                }

                return { success: false, error: { lastName: SignUpErrorKey.Unknown } };
            }

            const errorKeys = result.error.errors.reduce(
                (fieldErrors, { key, description }) => ({ ...fieldErrors, [key]: description }),
                {}
            );

            return { success: false, error: errorKeys };
        } catch (error) {
            setIsLoading(false);
            defaultRequestErrorHandler(error, addNotification);

            return { success: false, error: { lastName: SignUpErrorKey.Unknown } };
        }
    };

    return { isLoading, signUp };
};

export { useSignUp };
