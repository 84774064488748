import { useState } from 'react';

import { useCaptcha } from '@hh.ru/hhcaptcha';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';

import {
    LoginByCodeErrorKey,
    LoginByPasswordErrorKey,
    type PostLoginByCodePayload,
    type LoginWithCodeParams,
    type LoginWithCodeReturn,
    type LoginWithPasswordParams,
    type LoginWithPasswordReturn,
    type PostLoginByPasswordPayload,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import {
    postLoginByCode,
    postLoginByPassword,
    OperationTypeMap,
    isLoginByPasswordErrorKey,
    isLoginByCodeErrorKey,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/utils';

interface UseLogin {
    isLoading: boolean;
    loginWithCode: (params: LoginWithCodeParams) => Promise<LoginWithCodeReturn>;
    loginWithPassword: (params: LoginWithPasswordParams) => Promise<LoginWithPasswordReturn>;
}

const useLogin = (): UseLogin => {
    const [isLoading, setIsLoading] = useState(false);

    const captcha = useCaptcha();
    const { addNotification } = useNotification();

    const loginWithCode: UseLogin['loginWithCode'] = async ({
        login,
        code,
        accountType,
        backUrl = '/',
        isApplicantSignup = false,
    }) => {
        setIsLoading(true);

        try {
            const payload: PostLoginByCodePayload = {
                username: login,
                code,
                accountType,
                operationType: OperationTypeMap[accountType],
                backurl: backUrl,
                isApplicantSignup,
                remember: true,
            };

            const result = await postLoginByCode(payload);
            setIsLoading(false);

            if (result.success) {
                if (result.data.success) {
                    return { success: true, data: result.data };
                }

                const errorKey = result.data.verification?.key;
                const key = isLoginByCodeErrorKey(errorKey) ? errorKey : LoginByCodeErrorKey.Default;

                return { success: false, error: { key, data: { verification: result.data.verification } } };
            }

            return { success: false, error: result.error };
        } catch (error) {
            setIsLoading(false);
            defaultRequestErrorHandler(error, addNotification);

            return { success: false, error: { key: LoginByCodeErrorKey.Default } };
        }
    };

    const loginWithPassword: UseLogin['loginWithPassword'] = async ({ url, login, password, accountType, failUrl }) => {
        setIsLoading(true);

        try {
            const payload: PostLoginByPasswordPayload = {
                url,
                username: login,
                password,
                accountType,
                failUrl,
                remember: true,
            };
            captcha.addCaptchaParams(payload);

            const result = await postLoginByPassword(payload);
            setIsLoading(false);

            if (result.success) {
                captcha.updateCaptcha(result.data);

                if (result.data.hhcaptcha?.isBot || result.data.recaptcha.isBot) {
                    return { success: false, error: LoginByPasswordErrorKey.Captcha };
                }

                if (!result.data.loginError) {
                    return { success: true, data: result.data };
                }

                const errorKey = result.data.loginError.code;
                const key = isLoginByPasswordErrorKey(errorKey) ? errorKey : LoginByPasswordErrorKey.Unknown;

                return { success: false, error: key };
            }

            return { success: false, error: result.error };
        } catch (error) {
            setIsLoading(false);
            defaultRequestErrorHandler(error, addNotification);

            return { success: false, error: LoginByPasswordErrorKey.Unknown };
        }
    };

    return { isLoading, loginWithCode, loginWithPassword };
};

export { useLogin };
