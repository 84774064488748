import type { TranslatedComponent } from '@hh.ru/front-static-app';
import { translation } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

import { useAccountTypeSwitch } from 'src/components/AccountLogin/hooks/useAccountTypeSwitch';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    label: 'applicant.login.step.enterCredentials.employerLink',
};

const EmployerLoginLink: TranslatedComponent = ({ trls }) => {
    const { onSwitchTo } = useAccountTypeSwitch();

    const handleClick = (): void => {
        onSwitchTo(UserType.Employer);
    };

    return (
        <Button size="large" style="accent" mode="tertiary" onClick={handleClick}>
            {trls[TrlKeys.label]}
        </Button>
    );
};

export default translation(EmployerLoginLink);
