import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCaptcha } from '@hh.ru/hhcaptcha';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';

import {
    GenerateOtpResponseKey,
    type PostGenerateOtpPayload,
    type SendOtpCodeParams,
    type SendOtpCodeReturn,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp/types';
import { postGenerateOtp } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp/utils';

const otpAction = makeSetStoreField('otp');

interface UseOtpHook {
    isLoading: boolean;
    sendOtpCode: (params: SendOtpCodeParams) => Promise<SendOtpCodeReturn>;
}

const useOtp = (): UseOtpHook => {
    const [isLoading, setIsLoading] = useState(false);

    const captcha = useCaptcha();
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const sendOtpCode: UseOtpHook['sendOtpCode'] = async (params) => {
        setIsLoading(true);

        try {
            const payload: PostGenerateOtpPayload = { ...params };
            captcha.addCaptchaParams(payload);

            const result = await postGenerateOtp(payload);
            setIsLoading(false);

            if (result.success) {
                captcha.updateCaptcha(result.data);

                if (result.data.hhcaptcha?.isBot || result.data.recaptcha?.isBot) {
                    return { success: false, error: GenerateOtpResponseKey.Captcha };
                }

                dispatch(otpAction(result.data.otp));

                if (
                    result.data.key === GenerateOtpResponseKey.CodeSendOk ||
                    result.data.key === GenerateOtpResponseKey.CodeSendBlocked
                ) {
                    return { success: true, data: result.data };
                }

                return { success: false, error: result.data.key };
            }

            return { success: false, error: result.error };
        } catch (error) {
            setIsLoading(false);
            defaultRequestErrorHandler(error, addNotification);

            return { success: false, error: GenerateOtpResponseKey.Unknown };
        }
    };

    return { isLoading, sendOtpCode };
};

export { useOtp };
