import { useSelector } from '@hh.ru/front-static-app';

import type { Country } from 'src/models/allowedCountriesForSmsRegistration';
import { CountryCode, CountryId } from 'src/models/countryId';

const CountryCodeToIdMap: Record<CountryCode, CountryId> = {
    [CountryCode.Azerbaijan]: CountryId.Azerbaijan,
    [CountryCode.Belarus]: CountryId.Belarus,
    [CountryCode.Georgia]: CountryId.Georgia,
    [CountryCode.Kazakhstan]: CountryId.Kazakhstan,
    [CountryCode.Kirghizistan]: CountryId.Kirghizistan,
    [CountryCode.Russia]: CountryId.Russia,
    [CountryCode.Uzbekistan]: CountryId.Uzbekistan,
};

const CountryIdToCodeMap: Record<CountryId, CountryCode> = {
    [CountryId.Azerbaijan]: CountryCode.Azerbaijan,
    [CountryId.Belarus]: CountryCode.Belarus,
    [CountryId.Georgia]: CountryCode.Georgia,
    [CountryId.Kazakhstan]: CountryCode.Kazakhstan,
    [CountryId.Kirghizistan]: CountryCode.Kirghizistan,
    [CountryId.Russia]: CountryCode.Russia,
    [CountryId.Uzbekistan]: CountryCode.Uzbekistan,
};

interface UseSmsRegistration {
    countryCode: CountryCode;
    isSmsRegistrationAllowed: boolean;
    allowedCountries: Country[];
}

const useSmsRegistration = (): UseSmsRegistration => {
    const allowedCountriesForSmsRegistration = useSelector((state) => state.allowedCountriesForSmsRegistration);
    const countryId = useSelector((state) => state.countryId);

    const countryCode = CountryIdToCodeMap[countryId];

    const isSmsRegistrationAllowed = allowedCountriesForSmsRegistration
        .map(({ countryCode }) => CountryCodeToIdMap[countryCode])
        .includes(countryId);

    const allowedCountries: UseSmsRegistration['allowedCountries'] = allowedCountriesForSmsRegistration.map(
        ({ countryCode, countryName }) => ({
            code: countryCode,
            name: countryName,
        })
    );

    return { countryCode, isSmsRegistrationAllowed, allowedCountries };
};

export { useSmsRegistration };
