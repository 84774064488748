import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'src/hooks/useSelector';

const useRegisterEmployerLink = () => {
    const authEmployerUrl = useSelector((state) => state.authUrl?.['auth-employer-url']);
    const anonymousUserType = useSelector((state) => state.anonymousUserType);

    const elementRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (elementRef.current) {
            const url = urlParser(document.referrer);

            Analytics.sendHHEventElementShown(elementRef.current, {
                name: 'account-login-employer-registration',
                anonymousUserType,
                referrer: url.pathname,
                host: url.host,
            });
        }
    }, [anonymousUserType]);

    return {
        onClick: () => {
            const url = urlParser(document.referrer);

            Analytics.sendHHEventButtonClick('account-login-employer-registration', {
                anonymousUserType,
                referrer: url.pathname,
                host: url.host,
            });
        },
        dataQa: 'account-login-signup-employer',
        to: authEmployerUrl || '',
        elementRef,
    };
};

export { useRegisterEmployerLink };
