import { FC, PropsWithChildren } from 'react';

import { Link } from '@hh.ru/magritte-ui';

import { AppStore } from 'src/app/store';
import { useDispatchedFormField } from 'src/hooks/react-final-form/useDispatchedFormField';
import { authUrlUpdate } from 'src/models/authUrl';

interface ApplicantAuthMethodSwitcherProps {
    watchedFieldName?: string;
    onClick?: () => void;
}

const ApplicantAuthMethodSwitcher: FC<ApplicantAuthMethodSwitcherProps & PropsWithChildren> = ({
    watchedFieldName,
    onClick,
    ...rest
}) => {
    const valueSelector = ({ authUrl }: AppStore) => authUrl?.['login-field-value-entered'];
    const onDispatch = (value: string) => authUrlUpdate({ 'login-field-value-entered': value });
    const handleClick = useDispatchedFormField({
        watchedFieldName,
        valueSelector,
        onDispatch,
        handler: () => onClick?.(),
    });
    return <Link {...rest} typography="label-2-regular" onClick={handleClick} />;
};

export default ApplicantAuthMethodSwitcher;
